import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TeiCta = ( { meta } ) => {

	return (
		<>
			<div className="cta__bottom-content">
				<div className="row">
					<div className="column small-12 medium-6">
						<p>{meta.leftContent}</p>
					</div>
					<div className="column small-12 medium-6">
						<p>{meta.rightContent}</p>
					</div>
				</div>
			</div>
		</>
	)
}

TeiCta.propTypes = {
	meta: PropTypes.object.isRequired,
}

export default TeiCta;
