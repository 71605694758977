import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import ArrowBlueDown from '../../icons/arrow-blue-down';

const RevenueGraph = ( { type, title, improvement, barheight, graphValuePublisher, graphLablePublisher, graphValueOpenweb, graphLableOpenweb } ) => {

	const [ ref, inView ] = useInView( {
		threshold: 0.1,
		triggerOnce: false,
	} );

	const isValidHttpUrl = string => {
		let url;

		try {
		  url = new URL( string );
		} catch ( _ ) {
		  return false;
		}

		return url.protocol === "http:" || url.protocol === "https:";
	}

	const getFormattedPublisherName = url => {

		const isValidUrl = isValidHttpUrl( url );

		if ( ! isValidUrl ) {
			return url;
		}

		let newUrl = new URL( url );
		return newUrl.hostname;

	}

	return (
		<div className={ `revenue__graph-col ${ 'openweb' === type ? 'average_session_duration' : '' }` }>
			<p className="graph__title">{ title }</p>
			<div className="percentage">
				<span>{ improvement }%</span>&nbsp;
				<ArrowBlueDown />
			</div>
			<div className={`graph__container ${ inView ? 'in_view' : '' }`} ref={ ref }>
				<div className="graph__bar graph__bar--publisher" style={ { height: barheight } }>
					<div className="graph__value">{ graphValuePublisher }</div>
					<div className="graph__label">{ getFormattedPublisherName( graphLablePublisher ) }</div>
				</div>
				<div className="graph__bar graph__bar--openweb" style={ { height: '267px' } }>
					<div className="graph__value">{ graphValueOpenweb }</div>
					<div className="graph__label">{ getFormattedPublisherName( graphLableOpenweb ) }</div>
				</div>
			</div>
		</div>
	)
}

RevenueGraph.prototype = {
	title: PropTypes.string,
	improvement: PropTypes.string,
	barheight: PropTypes.string,
	graphValuePublisher: PropTypes.string,
	graphLablePublisher: PropTypes.string,
	graphValueOpenweb: PropTypes.string,
	graphLableOpenweb: PropTypes.string,
}

export default RevenueGraph;
