/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from "gatsby"
import { isEmpty } from 'lodash';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import TeiHero from '../../components/tei-result/hero';
import TeiRevenue from '../../components/tei-result/tei-revenue';
import TeiChart from '../../components/tei-result/tei-chart';
import TeiCta from '../../components/tei-result/cta';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import RelatedPosts from "../../components/blogs/related-posts"

const TeiResult = ( props ) => {

	const [ generatedValue, setGeneratedValue ] = useState( null );

	const {
		pageContext:  {
			page,
			page: {
				title,
				excerpt,
				uri,
				seo,
				teiResultPageMeta: { section1, section2, section3, section4, listOfPost },
			}
		},
		location: {
			state
		}
	} = props;

	useEffect( () => {
		if ( state && state.genaratedValues ) {
			setGeneratedValue( state.genaratedValues );
		} else {
			navigate(
				'/impact-calculator',
			)
		}

		return () => null
	}, [] ); // eslint-disable-line

	if ( ! generatedValue ) {
		return null;
	}

	const openGraphImage = getOgImage( seo );

	/**
	 * Function to get random item from array.
	 *
	 * @param array items array
	 * @return array item array
	 */
	const getRandomItem = items => {
		const index = Math.floor( Math.random() * items.length )
		return items.splice(index, 1)
	}

	let randomPostItems
	if ( ! isEmpty( listOfPost ) ) {
		if ( listOfPost.length > 2 ) {
			randomPostItems = [...getRandomItem( listOfPost ), ...getRandomItem( listOfPost )]
		} else if ( listOfPost.length === 1 ) {
			randomPostItems = [...getRandomItem( listOfPost )]
		}
	}

	return (
		<Layout title={ title } uri={ uri } className="tei-result">
			<SEO
				title={ page.title }
				seoData={ seo }
				uri={ uri }
				header={ { siteTitle: 'OpenWeb' } }
				openGraphImage={ openGraphImage }
			/>
			<TeiHero title={ section1.title } excerpt={ excerpt } />
			<TeiRevenue section1={ section1 } section2={ section2 } generatedValue={ generatedValue } />
			<TeiChart meta={ section3 } />
			<TeiCta meta={ section4 }/>
			{ randomPostItems && <RelatedPosts eventTracking={true} posts={ randomPostItems } title='Discover more community engagement tools and insights to grow your business.' /> }
		</Layout>
	)
};

TeiResult.propTypes = {
	pageContext: PropTypes.object
};


export default TeiResult;
