import React from 'react';
import PropTypes from 'prop-types';

import Image from '../../image';

import './style.scss';

const TeiChart = ( { meta } ) => {

	return (
		<section className="tei__chart-section">
			<div className="row align-center">
				<div className="column small-12 medium-10">
					<h2 className="tei-chart__title">{meta.title}</h2>
					<p className="tei-chart__subtitle">{meta.subtitle}</p>
					<div className="tei-chart__graph">
						<Image { ...meta.pieChartImage } />
					</div>
				</div>
			</div>

			<div className="tei-chart__note">{meta.note}</div>

			<div className="tei-preview__graph">
				<Image { ...meta.multiDeviceImage } />
			</div>

		</section>
	)
}

TeiChart.propTypes = {
	meta: PropTypes.object.isRequired,
}

export default TeiChart;
