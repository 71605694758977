import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import ArrowBlueDown from '../../icons/arrow-blue-down';

const paddableVerticals = [
	{
		key: 'Tech',
		paddValue: 3.2
	},
	{
		key: 'Gaming',
		paddValue: 2.6
	},
	{
		key: 'Fashion',
		paddValue: 6
	},
	{
		key: 'Fitness/Health',
		paddValue: 3.8
	},
	{
		key: 'Food/Drink',
		paddValue: 6
	},
	{
		key:  'Travel',
		paddValue: 7.5
	}
];

const Retention = ( { meta, generatedValue, calcPercent } ) => {

	const [ ref, inView ] = useInView( {
		threshold: 0.1,
		triggerOnce: false,
	} );

	const averageRetentionRate = generatedValue.selectedFormula.averageRetentionRate;

	const getRetentionBarWidth = ( value ) => {
		const chosenVertical = generatedValue.selectedFormula.verticalType;

		let paddValue = 2;
		const chosenVerticalObject = paddableVerticals.find( item => chosenVertical === item.key );

		if ( chosenVerticalObject ) {
			paddValue = chosenVerticalObject.paddValue;
		}
		let result = value * paddValue;

		return `${result}%`;
	};

	return (
		<section className="tei__retention-section">
			<div className="row align-center">
				<div className="column small-12 medium-10">
					<h2 className="retention__title">{ meta.title }</h2>
				</div>
				<div className="column medium-12 large-10">

					<p className="retention__subtitle">{ meta.subtitle }</p>
					<div className={`retention__graph ${ inView ? 'in_view' : '' }`} ref={ ref }>
						<div className="row retention__openweb align-middle">
							<div className="column medium-3 large-2 small-12">
								<div className="retention__left-col">
									<div className="name">Active user</div>
								</div>
							</div>
							<div className="column medium-9 large-10 small-12">
								<div className="retention__right-col retention__col">
									<div className="retention__bar" style={ { width: inView ? getRetentionBarWidth( averageRetentionRate.active ) : 0, opacity: inView ? 1 : 0 } }>
										<div className="percentage">{ averageRetentionRate.active }%</div>
										<div className="retention__content" style={ { left: inView ? getRetentionBarWidth( averageRetentionRate.active ) : 0 } }>
											<span>{ calcPercent( averageRetentionRate.active, averageRetentionRate.nonActive ).toFixed() }%</span>&nbsp;
											<ArrowBlueDown />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row retention__publisher align-middle">
							<div className="column medium-3 large-2 small-12">
								<div className="retention__left-col">
									<div className="name">Non-active user</div>
								</div>
							</div>
							<div className="column medium-9 large-10 small-12">
								<div className="retention__right-col retention__col">
									<div className="retention__bar" style={ { width: inView ? getRetentionBarWidth( averageRetentionRate.nonActive ) : 0, opacity: inView ? 1 : 0 } }>
										<div className="percentage">{ averageRetentionRate.nonActive }%</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="retention__note">{ meta.note }</div>
				</div>
			</div>
		</section>
	)
}

Retention.prototype = {
	meta: PropTypes.string.isRequired,
	generatedValue: PropTypes.object.isRequired
}

export default Retention;
