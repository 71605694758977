/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import ArticleCard from '../article-card';
import { postSlug, sendContactClickEvent } from "./../../../utils/functions"

import './style.scss';

/**
 * RelatedPosts Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const RelatedPosts = ( { posts, title, eventTracking } ) => {

	if ( isEmpty( posts ) ) {
		return null;
	}

	const handleLinkClick = ( index ) => {
		if ( true === eventTracking && 0 === index ) {
			sendContactClickEvent( '1st Blog', 'Blog1' );
		} else if ( true === eventTracking && 1 === index ) {
			sendContactClickEvent( '2nd Blog', 'Blog2' );
		}
	}

	return (
		<section className="related-posts" >
			<h3 className="related-posts__section-title">{ title ? title : 'You might be interested in these:' }</h3>
			<div className="related-posts__articles row">
				{ posts.map( ( post, index ) => (
					<div key={ `${ post.postId }-${ index }` } className="small-12 medium-6 columns column-block">
						<Link className="related-posts-articles__card-link" to={ postSlug( post.uri ) } onClick={ () => handleLinkClick( index ) }>
							<ArticleCard
								post={ post }
								animate={ false }
								isWide={ false }
							/>
						</Link>
					</div>
				) ) }
			</div>
		</section>
	);
};

RelatedPosts.propTypes = {
	posts: PropTypes.array,
};

RelatedPosts.defaultProps = {
	posts: [],
};

export default RelatedPosts;
