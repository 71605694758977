import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { sanitize } from '../../../utils/functions';

import './style.scss';

const Hero = ( { title, excerpt } ) => {
	return (
		<section className="tei__result-hero-section">
			<div className="row align-center">
				<div className="column medium-12 large-10">
					<h1 className="hero__title">{ title }</h1>
				</div>
			</div>
			{ ! isEmpty( excerpt) && (
				<div className="row align-center">
					<div className="column medium-12 large-10">
						<div className="hero__excerpt" dangerouslySetInnerHTML={ { __html: sanitize( 'alsdkjf a' ) } }></div>
					</div>
				</div>
			) }

		</section>
	)
}

Hero.propTypes = {
	title: PropTypes.string.isRequired,
	excerpt: PropTypes.string
}

Hero.defaultProps = {
	excerpt: ''
}

export default Hero;
