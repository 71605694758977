import React from 'react';
import PropTypes from 'prop-types';

import Retention from './retention';
import RevenueGraph from './revenue-graph'


import './style.scss';

const formatCash = n => {
	if ( n < 1e3 ) return n.toFixed( 1 );
	if ( n >= 1e3 && n < 1e6 ) return + ( n / 1e3 ).toFixed( 1 ) + "K";
	if ( n >= 1e6 && n < 1e9 ) return + ( n / 1e6 ).toFixed( 1 ) + "M";
	if ( n >= 1e9 && n < 1e12 ) return + ( n / 1e9 ).toFixed( 1 ) + "B";
	if ( n >= 1e12 ) return +( n / 1e12 ).toFixed( 1 ) + "T";
};

const getPercentage = ( number, percent ) => {
	return (number * percent) / 100;
}

const calcPercent = ( rootValue, calcValue ) => {
	const percent = (rootValue - calcValue) / calcValue * 100;
	return percent;
}

const calcHeightPercent = ( rootValue, calcValue ) => {
	const percent = (100 * calcValue) / rootValue;
	return percent;
}

const calcHeight = ( rootValue, calcValue ) => {
	const height = ( rootValue * calcValue ) / 100;
	return `${height}px`
}

const getFormatedTime = ( seconds ) => {
	let minute = seconds / 60;
	minute = Number( minute ).toFixed();
	minute = String( minute ).padStart( 2, 0 );

	let second = seconds % 60;
	second = second.toFixed();
	second = String( second ).padStart( 2, 0 );

	return `${minute}:${second}`;
}

const TeiRevenue = ( { section1, section2, generatedValue } ) => {

	return (
		<>
			<section className="tei__revenue-section">
				<div className="row align-center">
					<div className="column medium-12 large-10">
						<div className="revenue__title">{ section1.revenueTitle }</div>
						<div className="revenue__subtitle">{ section1.revenueSubtitle }</div>
						<div className="yearly_revenue_box">
							<span>${ formatCash( getPercentage( generatedValue.yearlyRevenue, 90 ) ) }</span>
							&nbsp;-&nbsp;
							<span>${ formatCash( getPercentage( generatedValue.yearlyRevenue, 110 ) ) }</span>
						</div>

						<div className="row align-justify">
							<div className="column small-12 medium-6 large-5">
								<RevenueGraph
									type="publisher"
									title={ section1.leftGraphTitle }
									improvement={ generatedValue.selectedFormula.improvementInPageview }
									barheight={ calcHeight( 267, calcHeightPercent( generatedValue.liftInPageviews, generatedValue.averagePageViewsPerSession ) ) }
									graphValuePublisher={  generatedValue.averagePageViewsPerSession }
									graphLablePublisher={ generatedValue.publisherName }
									graphValueOpenweb={ generatedValue.liftInPageviews.toFixed( 1 ) }
									graphLableOpenweb={ 'With OpenWeb' }
								/>
							</div>
							<div className="column small-12 medium-6 large-5">
								<RevenueGraph
									type="openweb"
									title={ section1.rightGraphTitle }
									improvement={ generatedValue.selectedFormula.improvementInTimePerDay }
									barheight={ calcHeight( 267, calcHeightPercent( generatedValue.liftInSessionDuration, generatedValue.totalSessionDuration ) ) }
									graphValuePublisher={  getFormatedTime( generatedValue.totalSessionDuration ) }
									graphLablePublisher={ generatedValue.publisherName}
									graphValueOpenweb={ getFormatedTime( generatedValue.liftInSessionDuration ) }
									graphLableOpenweb={ 'With OpenWeb' }
								/>
							</div>
						</div>
					</div>
				</div>

			</section>

			<Retention meta={ section2 } generatedValue={ generatedValue } calcPercent={ calcPercent } />
		</>
	)
}

TeiRevenue.propTypes = {
	section1: PropTypes.object.isRequired,
	section2: PropTypes.object.isRequired,
}

TeiRevenue.defaultProps = {
	excerpt: ''
}

export default TeiRevenue;
